import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter-tight\"}],\"variableName\":\"interTight\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../styles/fonts/EditorsNote/EditorsNote-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../styles/fonts/EditorsNote/EditorsNote-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-editors-note\"}],\"variableName\":\"editorsNote\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/apps/web/src/app/_analytics/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPrivyProvider"] */ "/vercel/path0/apps/web/src/app/_auth/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingNav"] */ "/vercel/path0/apps/web/src/app/_components/floating-navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/assets/wordmark.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/styles/globals.css");

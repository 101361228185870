'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { http } from 'viem';
import { mainnet } from 'viem/chains';
import { type PrivyClientConfig, PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider, createConfig } from '@privy-io/wagmi';
const queryClient = new QueryClient();
export const wagmiConfig = createConfig({
  chains: [mainnet],
  transports: {
    [mainnet.id]: http()
  }
});
/** todo: auto connect wallet */
/** todo: support other chains */

const privyConfig: PrivyClientConfig = {
  loginMethods: ['wallet', 'farcaster']
};
export function CSPrivyProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <PrivyProvider appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID!} config={privyConfig} data-sentry-element="PrivyProvider" data-sentry-component="CSPrivyProvider" data-sentry-source-file="provider.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="provider.tsx">
        <WagmiProvider config={wagmiConfig} data-sentry-element="WagmiProvider" data-sentry-source-file="provider.tsx">{children}</WagmiProvider>
      </QueryClientProvider>
    </PrivyProvider>;
}
'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Wordmark from '~/assets/wordmark.svg';
import { motion, AnimatePresence, useScroll, useMotionValueEvent } from 'framer-motion';
import { cn } from '~/utils/cn';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Linkout } from './link-out';
import { OUTPAINT_FARCASTER_CHANNEL } from '~/utils/constants';
type NavItem = {
  name: string;
  link: string;
  disabled?: boolean;
  icon?: JSX.Element;
};
export function FloatingNav({
  navItems,
  shouldHideOnScroll = false
}: {
  navItems: NavItem[];
  shouldHideOnScroll?: boolean;
}) {
  const pathname = usePathname();
  const {
    scrollYProgress
  } = useScroll();
  const [visible, setVisible] = useState(true);
  const filteredNavItems = navItems.filter(item => !(item.name === 'Back' && pathname === '/'));
  useMotionValueEvent(scrollYProgress, 'change', current => {
    if (!shouldHideOnScroll) {
      setVisible(true);
      return;
    }
    if (current < 0.05) {
      setVisible(true);
    } else {
      const previous = scrollYProgress.getPrevious() ?? 0;
      const direction = current - previous;
      setVisible(direction < 0);
    }
  });
  return <div className="fixed inset-x-0 top-5 z-[5000] mx-auto" data-sentry-component="FloatingNav" data-sentry-source-file="floating-navbar.tsx">
      <div className="container">
        <div className="flex items-center justify-between space-x-4">
          {/* Wordmark - Always visible */}
          <Link href="/" className="flex-shrink-0" data-sentry-element="Link" data-sentry-source-file="floating-navbar.tsx">
            <Wordmark className="h-6 w-auto text-black dark:text-white" data-sentry-element="Wordmark" data-sentry-source-file="floating-navbar.tsx" />
          </Link>

          {/* <AnimatePresence mode="wait">
            <motion.div
              initial={{
                opacity: 1,
                y: -100,
              }}
              animate={{
                y: visible ? 0 : -100,
                opacity: visible ? 1 : 0,
              }}
              transition={{
                duration: 0.2,
              }}
              className={
                'shadow-button-outline flex gap-4 rounded-full border border-transparent bg-white py-2 pl-8 pr-2 dark:border-white/[0.2] dark:bg-black'
              }
            >
              {filteredNavItems.map((navItem: NavItem) => (
                <Link
                  key={navItem.name}
                  href={navItem.link}
                  className={cn(
                    'relative flex items-center space-x-1 text-neutral-600 hover:text-neutral-500 dark:text-neutral-50 dark:hover:text-neutral-300',
                    navItem.disabled && 'cursor-not-allowed text-neutral-400',
                  )}
                >
                  <span className="block sm:hidden">{navItem.icon}</span>
                  <span className="hidden text-sm sm:block">
                    {navItem.name}
                  </span>
                </Link>
              ))}
              <Linkout />
            </motion.div>
           </AnimatePresence> */}

          {/* placeholder for connect */}
          <Linkout href={OUTPAINT_FARCASTER_CHANNEL} outline data-sentry-element="Linkout" data-sentry-source-file="floating-navbar.tsx">
            follow for points ↗
          </Linkout>
        </div>
      </div>
    </div>;
}
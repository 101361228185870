import Link from 'next/link';
interface LinkoutProps {
  outline?: boolean;
  href: string;
  children: React.ReactNode;
}
export function Linkout({
  outline = false,
  href,
  children
}: LinkoutProps) {
  return <Link target="_blank" rel="noopener noreferrer" href={href} className={`relative inline-block rounded-full ${outline ? 'border border-neutral-200 py-2 dark:border-white/[0.2]' : 'py-0 md:py-2'} bg-white px-4 text-sm font-medium text-black dark:text-white`} data-sentry-element="Link" data-sentry-component="Linkout" data-sentry-source-file="link-out.tsx">
      {children}
      {outline && <span className="absolute inset-x-0 -bottom-px mx-auto h-px w-1/2 bg-gradient-to-r from-transparent via-blue-500 to-transparent" />}
    </Link>;
}
'use client';

import { usePrivy } from '@privy-io/react-auth';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAccount, useEnsName } from 'wagmi';
if (typeof window !== 'undefined' && window.location.hostname === process.env.NEXT_PUBLIC_OUTPAINT_HOST!) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: true // auto capture pageviews
  });
}
export function CSPostHogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="CSPostHogProvider" data-sentry-source-file="provider.tsx">
      <PostHogAuthWrapper data-sentry-element="PostHogAuthWrapper" data-sentry-source-file="provider.tsx">{children}</PostHogAuthWrapper>
    </PostHogProvider>;
}
function PostHogAuthWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    address
  } = useAccount();
  const {
    ready,
    user,
    authenticated
  } = usePrivy();
  const {
    data: ensName
  } = useEnsName({
    address
  });

  /** regardless ensName is not ready, we send the rest of user data */
  useEffect(() => {
    if (ready && authenticated) {
      const {
        id,
        createdAt,
        ...rest
      } = user ?? {};
      posthog.identify(id, {
        createdAt: createdAt,
        ensName: ensName ?? undefined,
        ...rest
      });
    } else if (!authenticated) {
      posthog.reset();
    }
  }, [authenticated, ensName, ready, user]);
  return children;
}